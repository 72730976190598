import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Navbar from '../layout/Navbar';
import Loader from '../layout/Loader';
import Home from '../views/Home';
import Error from '../views/Error';
import Footer from '../layout/Footer';
import Property from '../views/property';
import PropertyDetails from '../views/property-details';
import AboutUs from '../views/about-us';
import Services from '../views/Services';
import ContactPage from '../views/ContactPage';
import Sell from '../views/Sell';
import Investments from '../views/Investments';
import Blog from '../views/Blog';
import Emprendimientos from '../views/Emprendimientos';
import AOS from 'aos';
import 'aos/dist/aos.css';

import ScrollToTop from '../shared/ScrollToTop';

function Routes() {
  AOS.init();

  return (
    <BrowserRouter>
      <Loader />
      <Navbar />
      <ScrollToTop />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/propiedades' exact component={Property} />
        <Route path='/propiedades/:operations?/:id?' exact component={PropertyDetails} />
        <Route path='/vender' component={Sell} />
        <Route path='/inversiones-en-el-exterior' component={Investments} />
        <Route path='/nosotros' component={AboutUs} />
        <Route path='/servicios' component={Services} />
        <Route path='/emprendimientos' component={Emprendimientos} />
        <Route path='/por-que-contratar-un-agente-inmobiliario' component={Blog} />
        <Route path='/contacto' component={ContactPage} />
        <Route path='*' component={Error} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default Routes;
