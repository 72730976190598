import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  disableLoading,
  setMetaInfo,
  setProperties,
  enableLoading,
  setFilter,
} from '../actions'
import axios from 'axios'

const useFetchProperties = (operationId) => {
  const dispatch = useDispatch()

  const { url, urlSearch, loading, properties } = useSelector((state) => ({
    url: state.properties.url,
    urlSearch: state.properties.urlSearch,
    loading: state.properties.loading,
    properties: state.properties.properties,
  }))

  useEffect(() => {
    dispatch(enableLoading())

    // ? si trae el param de operación filtra, de lo contrario hace fetch de todo

    if (typeof operationId === 'string') {
      dispatch(setFilter({ id: operationId, filter: 'operation_type' }))

      const params = {
        price_from: 0,
        price_to: 99999999,
        operation_types: [operationId],
        property_types: [1, 2, 3, 4, 5, 6, 7, 8],
      }

      const filterData = async () => {
        try {
          localStorage.setItem("fetchUrl", `${urlSearch}&data=${JSON.stringify(params)}`)
          const { data } = await axios.get(
            `${urlSearch}&data=${JSON.stringify(params)}`
          )

          dispatch(setProperties(data.objects))
          dispatch(setMetaInfo(data.meta))
        } catch (error) {
          console.error('FETCHING PROPERTIES: ', error)
        } finally {
          dispatch(disableLoading())
        }
      }

      filterData() // * se ejecuta la función
    } else {
      var fetchData = async () => {
        try {
          localStorage.setItem("fetchUrl", `${url}`)
          const { data } = await axios.get(url)
          const { objects, meta } = data

          dispatch(setProperties(objects))
          dispatch(setMetaInfo(meta))

        } catch (error) {
          console.error('FETCHING PROPERTIES: ', error)
        } finally {
          dispatch(disableLoading())
        }
      }

      fetchData() // * se ejecuta la función
    }
  }, [])

  return {
    properties,
    loading,
  }
}

export default useFetchProperties
